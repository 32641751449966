import { List, Datagrid, TextField, TextInput, SelectInput } from 'react-admin';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';
import { payoutStatusChoices } from 'choices';
import { UserLink } from 'resources/payoutMethods';

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput source="status" choices={payoutStatusChoices} alwaysOn />,
];

export const PayoutList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    filters={listFilters}
    bulkActionButtons={false}
  >
    <Datagrid>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="amount" />
      <TextField source="average_payout_amount" sortable={false} />
      <TextField source="mass_pay_payout_token" label="MassPay Payout ID" />

      <UserLink label="User" />
    </Datagrid>
  </List>
);
