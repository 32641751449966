import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { get } from 'lodash';

export const useLinkStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,

    '&:visited': {
      color: theme.palette.primary.main,
    },
    '&:active': {
      color: theme.palette.primary.main,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },

    textDecoration: 'none',
  },
}));

const PolymorphicLinkField = ({ record, source }) => {
  const styles = useLinkStyles();

  const id = get(record, [source, 'id']);
  const role = get(record, [source, 'role'], 'influencer');
  const resource = role === 'influencer' ? 'profiles' : 'users';
  const name = get(record, [source, 'display_name']) || get(record, [source, 'name']);
  const url = `/${resource}/${id}`;

  return (
    <Link
      className={styles.link}
      onClick={e => {
        e.stopPropagation();
      }}
      to={url}
    >
      {name} ({role})
    </Link>
  );
};

export const PayableField = ({ record }) => {
  const styles = useLinkStyles();

  const id = record?.payable_id;
  const name = record?.payable_type;
  const resource = name === 'Cart' ? 'carts' : 'gigs';
  const url = `/${resource}/${id}`;

  return (
    <Link
      className={styles.link}
      onClick={e => {
        e.stopPropagation();
      }}
      to={url}
    >
      {name} #{id}
    </Link>
  );
};

export const UserLinkField = ({ record, source }) => {
  const styles = useLinkStyles();

  const id = get(record, [source, 'id']);
  const name = get(record, [source, 'display_name']);
  const url = `/users/${id}`;

  return (
    <Link
      className={styles.link}
      onClick={e => {
        e.stopPropagation();
      }}
      to={url}
    >
      {name}
    </Link>
  );
};

export default PolymorphicLinkField;
