import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Datagrid,
  TextField,
  NumberField,
  DateField,
} from 'react-admin';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';

const listFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const ExperienceList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    filters={listFilters}
    sort={{ field: 'profiles_count', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="profiles_count" />
      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const ExperienceCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/experiences">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);

export const ExperienceEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="/experiences">
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const ExperienceShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <NumberField source="profiles_count" />
      <DateField source="created_at" />
    </SimpleShowLayout>
  </Show>
);
