import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Datagrid,
  TextField,
  NumberField,
  ReferenceArrayField,
  BooleanField,
  BooleanInput,
  NumberInput,
} from 'react-admin';

const listFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const TraitCategoryList = props => (
  <List {...props} pagination={false} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
    </Datagrid>
  </List>
);

export const TraitCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/trait_categories">
      <TextInput source="name" />
      <BooleanInput source="adult" />
    </SimpleForm>
  </Create>
);

export const TraitCategoryEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="/trait_categories">
      <TextInput source="name" />
      <TextInput source="question" />
      <NumberInput source="min" />
      <NumberInput source="max" />
      <NumberInput source="position" />
    </SimpleForm>
  </Edit>
);
