import { useSelector, useDispatch, batch } from 'react-redux';
import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  Input,
  InputAdornment,
  IconButton,
  ClickAwayListener,
  CircularProgress,
} from '@material-ui/core';
import { teal, red } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import {
  List,
  TextInput,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput,
  useRecordContext,
  toggleListItemExpand,
  useMutation,
  useNotify,
  useRefresh,
  createMuiTheme,
} from 'react-admin';

import Pagination from 'components/Pagination';
import { defaultPerPage } from 'utils/helpers';
import { useEffect, useRef, useState } from 'react';
import dataProvider from 'dataProvider';
import { JsonField } from 'react-admin-json-view';

const subheaderStyle = { display: 'flex', justifyContent: 'space-between' };
const linkStyle = { color: 'blue', textDecoration: 'underline', cursor: 'pointer' };

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,

  <ReferenceInput source="network_id" reference="networks" alwaysOn>
    <SelectInput />
  </ReferenceInput>,
];

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: red,
  },
});

const Screenshots = () => {
  const record = useRecordContext();

  if (!record) return null;

  const profile = record.profile_screenshot_url;
  const gender = record.gender_screenshot_url;
  const age = record.age_screenshot_url;
  const location = record.location_screenshot_url;
  const analytics = record.analytics_screenshot_url;

  const flexColumns = age ? 3 : 4;

  return (
    <ThemeProvider theme={theme}>
      <Grid container spacing={2}>
        {profile && record.profile_screenshot_status === 'pending_verification' && (
          <Grid item xs={flexColumns}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader
                subheader={
                  <div style={subheaderStyle}>
                    <span>Profile screenshot</span>
                    <a
                      style={linkStyle}
                      href={record.profile_screenshot_download_url}
                      alt="download"
                    >
                      Download
                    </a>
                  </div>
                }
              />

              <CardMedia className="media" image={profile} />

              <div className="preview-container">
                <img src={profile} alt="Profile screenshot" />
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <RejectScreenshotButton record={record} screenshot="profile_screenshot" />
                <ApproveScreenshotButton record={record} screenshot="profile_screenshot" />
              </div>
            </Card>
          </Grid>
        )}

        {age && record.age_screenshot_status === 'pending_verification' && (
          <Grid item xs={flexColumns}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader
                subheader={
                  <div style={subheaderStyle}>
                    <span>Age screenshot</span>
                    <a style={linkStyle} href={record.age_screenshot_download_url} alt="download">
                      Download
                    </a>
                  </div>
                }
              />

              <CardMedia className="media" image={age} />

              <div className="preview-container">
                <img src={age} alt="Age screenshot" />
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <RejectScreenshotButton record={record} screenshot="age_screenshot" />
                <ApproveScreenshotButton record={record} screenshot="age_screenshot" />
              </div>
            </Card>
          </Grid>
        )}

        {gender && record.gender_screenshot_status === 'pending_verification' && (
          <Grid item xs={flexColumns}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader
                subheader={
                  <div style={subheaderStyle}>
                    <span>Gender screenshot</span>
                    <a
                      style={linkStyle}
                      href={record.gender_screenshot_download_url}
                      alt="download"
                    >
                      Download
                    </a>
                  </div>
                }
              />

              <CardMedia className="media" image={gender} />

              <div className="preview-container">
                <img src={gender} alt="Gender screenshot" />
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <RejectScreenshotButton record={record} screenshot="gender_screenshot" />
                <ApproveScreenshotButton record={record} screenshot="gender_screenshot" />
              </div>
            </Card>
          </Grid>
        )}

        {location && record.location_screenshot_status === 'pending_verification' && (
          <Grid item xs={flexColumns}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader
                subheader={
                  <div style={subheaderStyle}>
                    <span>Location screenshot</span>
                    <a
                      style={linkStyle}
                      href={record.location_screenshot_download_url}
                      alt="download"
                    >
                      Download
                    </a>
                  </div>
                }
              />

              <CardMedia className="media" image={location} />

              <div className="preview-container">
                <img src={location} alt="Location screenshot" />
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <RejectScreenshotButton record={record} screenshot="location_screenshot" />
                <ApproveScreenshotButton record={record} screenshot="location_screenshot" />
              </div>
            </Card>
          </Grid>
        )}

        {analytics && record.analytics_screenshot_status === 'pending_verification' && (
          <Grid item xs={flexColumns}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader
                subheader={
                  <div style={subheaderStyle}>
                    <span>Analytics screenshot</span>
                    <a
                      style={linkStyle}
                      href={record.analytics_screenshot_download_url}
                      alt="download"
                    >
                      Download
                    </a>
                  </div>
                }
              />

              <CardMedia className="media" image={analytics} />

              <div className="preview-container">
                <img src={analytics} alt="Analytics screenshot" />
              </div>

              <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 10 }}>
                <RejectScreenshotButton record={record} screenshot="analytics_screenshot" />
                <ApproveScreenshotButton record={record} screenshot="analytics_screenshot" />
              </div>
            </Card>
          </Grid>
        )}

        {record.stats && (
          <Grid item xs={12}>
            <Card
              elevation={0}
              className="screenshot-card"
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <div
                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
              >
                <CardHeader subheader="Extracted data" />
                <JsonField
                  reactJsonOptions={{
                    collapsed: true,
                  }}
                  record={record}
                  source="stats"
                />
              </div>

              {record.popular_gender && record.gender_percentage && (
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <CardHeader subheader="Gender" />
                  {`${record.gender_percentage}% ${record.popular_gender}`}
                </div>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

const EditableField = ({ record, source, type = 'text' }) => {
  const inputRef = useRef();
  const [editing, setEditing] = useState(false);
  const initialValue = record[source];
  const [value, setValue] = useState(initialValue);
  const [mutate] = useMutation();
  const notify = useNotify();

  const handleChange = e => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    if (value === initialValue) {
      setEditing(false);
      return;
    }

    mutate(
      {
        type: 'update',
        resource: 'approvals',
        payload: {
          id: record.id,
          data: { [source]: value },
        },
      },
      {
        onSuccess: () => setEditing(false),
        onFailure: error => notify(error.message, 'error'),
      }
    );
  };

  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  if (editing) {
    return (
      <ClickAwayListener onClickAway={() => setEditing(false)}>
        <Input
          inputRef={inputRef}
          type={type}
          value={value}
          onChange={handleChange}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              handleSave();
            } else if (e.key === 'Escape') {
              setEditing(false);
            }
          }}
          style={{ width: 150 }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="Save" onClick={handleSave}>
                <SaveIcon />
              </IconButton>
            </InputAdornment>
          }
        />
      </ClickAwayListener>
    );
  } else {
    return (
      <span
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          setEditing(true);
        }}
        style={{
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {value}
      </span>
    );
  }
};

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .update('influencer_networks', {
        id: record.id,
        data: {
          status: 'verified',
          profile_screenshot_status: 'verified',
          username_screenshot_status: 'verified',
          location_screenshot_status: 'verified',
          gender_screenshot_status: 'verified',
          analytics_screenshot_status: 'verified',
        },
      })
      .then(() => {
        refresh();
        notify('Successfully approved', { type: 'info' });
      });
  };

  return (
    <Button variant="contained" color="primary" size="small" onClick={handleClick}>
      Approve
    </Button>
  );
};

const shouldUpdateStatus = (record, screenshot, status) => {
  let statusByScreenshot = {};

  [
    'profile_screenshot_status',
    'location_screenshot_status',
    'gender_screenshot_status',
    'analytics_screenshot_status',
  ].forEach(key => {
    if (key === `${screenshot}_status`) {
      statusByScreenshot[`${screenshot}_status`] = status;
    } else {
      statusByScreenshot[key] = record[key];
    }
  });

  return (
    statusByScreenshot['profile_screenshot_status'] === status &&
    (statusByScreenshot['analytics_screenshot_status'] === status ||
      (statusByScreenshot['location_screenshot_status'] === status &&
        statusByScreenshot['gender_screenshot_status'] === status))
  );
};

const RejectButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .update('influencer_networks', {
        id: record.id,
        data: {
          status: 'rejected',
          profile_screenshot_status: 'rejected',
          location_screenshot_status: 'rejected',
          gender_screenshot_status: 'rejected',
          analytics_screenshot_status: 'rejected',
        },
      })
      .then(() => {
        refresh();
        notify('Successfully rejected', { type: 'info' });
      });
  };

  return (
    <Button
      variant="contained"
      color="secondary"
      size="small"
      onClick={handleClick}
      style={{ marginRight: 5 }}
    >
      Reject
    </Button>
  );
};

const RemoveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .update('influencer_networks', {
        id: record.id,
        data: { approval_hidden: true },
      })
      .then(() => {
        refresh();
        notify('Successfully removed from approvals', { type: 'info' });
      });
  };

  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      onClick={handleClick}
      style={{ marginRight: 5 }}
    >
      Remove
    </Button>
  );
};

const ApproveScreenshotButton = ({ record, screenshot }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    const data = {
      [`${screenshot}_status`]: 'verified',
    };

    if (shouldUpdateStatus(record, screenshot, 'verified')) {
      data['status'] = 'verified';
    }

    dataProvider
      .update('influencer_networks', {
        id: record.id,
        data: data,
      })
      .then(() => {
        refresh();
        notify('Successfully verified screenshot', { type: 'info' });
      });
  };

  return (
    <Button variant="contained" color="primary" size="small" onClick={handleClick}>
      Approve
    </Button>
  );
};

const RejectScreenshotButton = ({ record, screenshot }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .update('influencer_networks', {
        id: record.id,
        data: {
          [`${screenshot}_status`]: 'rejected',
        },
      })
      .then(() => {
        refresh();
        notify('Successfully rejected screenshot', { type: 'info' });
      });
  };

  return (
    <Button variant="contained" color="secondary" size="small" onClick={handleClick}>
      Reject
    </Button>
  );
};

const ApproveAllButton = ({ selectedIds }) => {
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setLoading(true);

    dataProvider
      .updateMany('approvals', {
        ids: selectedIds,
        data: {
          status: 'verified',
          profile_screenshot_status: 'verified',
          location_screenshot_status: 'verified',
          gender_screenshot_status: 'verified',
          analytics_screenshot_status: 'verified',
        },
      })
      .then(() => {
        refresh();
        notify('Successfully approved all', { type: 'info' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      disabled={loading}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClick}
    >
      {loading ? <CircularProgress size="2rem" /> : 'Approve All'}
    </Button>
  );
};

const Buttons = props => (
  <ThemeProvider theme={theme}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <RemoveButton {...props} />
      <RejectButton {...props} />
      <ApproveButton {...props} />
    </div>
  </ThemeProvider>
);

export const ApprovalsList = props => {
  const dispatch = useDispatch();
  const list = useSelector(state => state?.admin?.resources?.['approvals']?.list);

  useEffect(() => {
    batch(() => {
      list.ids.forEach(id => {
        if (list.expanded.includes(id)) return;
        dispatch(toggleListItemExpand('approvals', id));
      });
    });
  }, [dispatch, list.ids, list.expanded]);

  return (
    <List
      {...props}
      filter={{
        status: 'pending_verification',
        approval_hidden: false,
        topLevel: { with_stats: true },
      }}
      sort={{ field: 'profile_id', order: 'DESC' }}
      filters={listFilters}
      bulkActionButtons={<ApproveAllButton />}
      perPage={defaultPerPage}
      pagination={<Pagination />}
    >
      <Datagrid expand={<Screenshots />}>
        <TextField source="id" />

        <ReferenceField source="profile_id" reference="profiles">
          <TextField source="display_name" />
        </ReferenceField>

        <ReferenceField source="network_id" reference="networks">
          <TextField source="name" />
        </ReferenceField>

        <EditableField source="username" type="text" />
        <EditableField source="followers" type="number" />
        <EditableField source="fans" type="number" />
        <EditableField source="top_creator_percentage" type="number" />
        <EditableField source="subscribers" type="number" />
        <EditableField source="likes" type="number" />

        <DateField source="created_at" />

        <Buttons />
      </Datagrid>
    </List>
  );
};
