import React from 'react';
import { Admin as ReactAdmin, EditGuesser, ListGuesser, Resource } from 'react-admin';
import InstagramIcon from '@material-ui/icons/Instagram';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PublicIcon from '@material-ui/icons/Public';
import FaceIcon from '@material-ui/icons/Face';
import PeopleIcon from '@material-ui/icons/People';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import CategoryIcon from '@material-ui/icons/Category';
import LowPriorityIcon from '@material-ui/icons/LowPriority';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import WorkIcon from '@material-ui/icons/Work';
import RateReviewIcon from '@material-ui/icons/RateReview';
import WalletIcon from '@material-ui/icons/AccountBalanceWallet';
// import ChatIcon from '@material-ui/icons/Chat';
import FlagIcon from '@material-ui/icons/Flag';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import PaymentIcon from '@material-ui/icons/Payment';
import PayoutIcon from '@material-ui/icons/MonetizationOn';
import HelpIcon from '@material-ui/icons/Help';
import WarningIcon from '@material-ui/icons/Warning';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { createBrowserHistory } from 'history';
import 'normalize.css';

import dataProvider from './dataProvider';
import authProvider from './authProvider';
import './App.css';

import { NetworkList, NetworkEdit, NetworkCreate } from 'resources/networks';
import {
  InventorySpaceList,
  InventorySpaceEdit,
  InventorySpaceCreate,
} from 'resources/inventorySpaces';
import { UserCreate, UserEdit, UserList } from 'resources/users';
import { ProfileCreate, ProfileEdit, ProfileList } from 'resources/profiles';
import { ExperienceCreate, ExperienceEdit, ExperienceList } from 'resources/experiences';
import { AudienceCreate, AudienceEdit, AudienceList } from 'resources/audiences';
import { CategoryCreate, CategoryEdit, CategoryList } from 'resources/categories';
import { SubCategoryCreate, SubCategoryEdit, SubCategoryList } from 'resources/subCategories';
import {
  InfluencerNetworkEdit,
  InfluencerNetworkList,
  InfluencerNetworkCreate,
} from 'resources/influencerNetworks';
import { GigInvitationList } from 'resources/gigInvitations';
import { InventoryItemEdit } from 'resources/inventoryItems';
import {
  LiveTimeOptionCreate,
  LiveTimeOptionEdit,
  LiveTimeOptionList,
} from 'resources/liveTimeOptions';
import { GigCreate, GigEdit, GigList } from 'resources/gigs';
import { ReviewEdit, ReviewList } from 'resources/reviews';
// import { ChatList } from 'resources/chats';
// import Chat from './components/chats/Chat';
import { MilestoneEdit, MilestoneList } from 'resources/milestones';
import { DiscountCodeCreate, DiscountCodeEdit, DiscountCodeList } from 'resources/discountCodes';
import { CartEdit, CartList } from 'resources/carts';
import { PaymentList } from 'resources/payments';
import { PaymentEdit } from './resources/payments';
import { PayoutList } from './resources/payouts';
import { SupportRequestList, SupportRequestEdit } from './resources/supportRequests';
import { ApprovalsList } from 'resources/approvals';
import { WalletList, AllWalletList } from 'resources/wallets';
import LoginPage from 'components/LoginPage';
import { PaymentRiskDetailShow, PaymentRiskDetailsList } from 'resources/paymentRiskDetails';
import { WishlistOptionCreate, WishlistOptionList } from 'resources/wishlistOptions';
import { WishlistOptionEdit } from './resources/wishlistOptions';
import { AnnouncementEdit, AnnouncementList } from 'resources/annoucements';
import { AnnouncementCreate } from './resources/annoucements';
import {
  TraitCategoryCreate,
  TraitCategoryEdit,
  TraitCategoryList,
} from 'resources/traitCategories';
import { TraitCreate, TraitEdit, TraitList } from 'resources/traits';

const history = createBrowserHistory();

const App = () => (
  <ReactAdmin
    loginPage={LoginPage}
    history={history}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="approvals" list={ApprovalsList} />
    <Resource
      name="payment_risk_details"
      list={PaymentRiskDetailsList}
      show={PaymentRiskDetailShow}
      icon={WarningIcon}
      options={{ label: 'Risky Transactions' }}
    />
    <Resource name="wallets" list={WalletList} icon={WalletIcon} />
    <Resource
      name="all_wallets"
      list={AllWalletList}
      icon={WalletIcon}
      options={{ label: 'All Wallets' }}
    />
    <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} icon={PeopleIcon} />
    <Resource
      name="profiles"
      list={ProfileList}
      create={ProfileCreate}
      edit={ProfileEdit}
      icon={FaceIcon}
    />
    <Resource
      name="influencer_networks"
      list={InfluencerNetworkList}
      edit={InfluencerNetworkEdit}
      create={InfluencerNetworkCreate}
    />
    <Resource name="gig_invitations" list={GigInvitationList} />
    <Resource
      name="networks"
      list={NetworkList}
      edit={NetworkEdit}
      create={NetworkCreate}
      icon={InstagramIcon}
    />
    <Resource
      name="inventory_spaces"
      list={InventorySpaceList}
      edit={InventorySpaceEdit}
      create={InventorySpaceCreate}
      icon={GroupWorkIcon}
    />

    <Resource
      name="wishlist_options"
      list={WishlistOptionList}
      edit={WishlistOptionEdit}
      create={WishlistOptionCreate}
      icon={LoyaltyIcon}
    />

    {/* <Resource
      name="audiences"
      list={AudienceList}
      create={AudienceCreate}
      edit={AudienceEdit}
      icon={PublicIcon}
    />
    <Resource
      name="experiences"
      list={ExperienceList}
      create={ExperienceCreate}
      edit={ExperienceEdit}
      icon={LoyaltyIcon}
    />
    <Resource
      name="categories"
      list={CategoryList}
      edit={CategoryEdit}
      create={CategoryCreate}
      icon={CategoryIcon}
    />
    <Resource
      name="sub_categories"
      list={SubCategoryList}
      edit={SubCategoryEdit}
      create={SubCategoryCreate}
      icon={LowPriorityIcon}
    /> */}

    <Resource
      name="trait_categories"
      list={TraitCategoryList}
      edit={TraitCategoryEdit}
      create={TraitCategoryCreate}
      icon={CategoryIcon}
    />
    <Resource
      name="traits"
      list={TraitList}
      edit={TraitEdit}
      create={TraitCreate}
      icon={PublicIcon}
    />

    <Resource
      name="announcements"
      list={AnnouncementList}
      edit={AnnouncementEdit}
      create={AnnouncementCreate}
      icon={AnnouncementIcon}
    />

    <Resource name="inventory_items" edit={InventoryItemEdit} />
    <Resource
      name="live_time_options"
      icon={AlarmOnIcon}
      list={LiveTimeOptionList}
      edit={LiveTimeOptionEdit}
      create={LiveTimeOptionCreate}
    />
    <Resource name="gigs" list={GigList} edit={GigEdit} create={GigCreate} icon={WorkIcon} />
    <Resource name="milestones" list={MilestoneList} edit={MilestoneEdit} icon={FlagIcon} />
    <Resource name="reviews" list={ReviewList} edit={ReviewEdit} icon={RateReviewIcon} />
    <Resource name="payments" list={PaymentList} edit={PaymentEdit} icon={PaymentIcon} />
    <Resource name="payouts" list={PayoutList} icon={PayoutIcon} />
    {/* <Resource name="chats" list={ChatList} show={Chat} icon={ChatIcon} /> */}
    <Resource name="carts" list={CartList} edit={CartEdit} icon={ShoppingCartIcon} />
    <Resource
      name="discount_codes"
      list={DiscountCodeList}
      edit={DiscountCodeEdit}
      create={DiscountCodeCreate}
      icon={MoneyOffIcon}
    />
    <Resource
      name="support_requests"
      list={SupportRequestList}
      edit={SupportRequestEdit}
      icon={HelpIcon}
    />
    <Resource name="inventories" />
    <Resource name="influencer_users" />
    <Resource name="advertiser_users" />
    <Resource name="schools" />
  </ReactAdmin>
);

export default App;
