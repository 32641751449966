import { useSelector, useDispatch, batch } from 'react-redux';
import { Button, Card, CardHeader, CardMedia, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Datagrid,
  TextField,
  useRecordContext,
  toggleListItemExpand,
  useNotify,
  useRefresh,
  BooleanField,
  TextInput,
  BooleanInput,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

import Pagination from 'components/Pagination';
import { defaultPerPage, parseError } from 'utils/helpers';
import { useEffect, useState } from 'react';
import { UserLinkField } from 'fields/PolymorphicLinkField';
import axios from 'utils/axios';

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
    backgroundPosition: 'top',
  },
}));

const Screenshots = () => {
  const record = useRecordContext();

  if (!record) return null;

  const identityDocument = record.identity_document_url;

  return (
    <Grid container spacing={2}>
      {identityDocument && (
        <>
          <Grid item xs={4}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader subheader="Identity Document" />
              <CardMedia className="media" image={identityDocument} />

              <div className="preview-container">
                <img src={identityDocument} alt="Identity Document" />
              </div>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card elevation={0} className="screenshot-card">
              <CardHeader subheader="Extracted Data" />

              <JsonField
                source="identity_document_data"
                record={record}
                reactJsonOptions={{
                  collapsed: 1,
                }}
              />

              <div className="preview-container">
                <img src={identityDocument} alt="Identity Document" />
              </div>
            </Card>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    axios
      .post(`/v1/wallets/${record.id}/verify_identity_document`)
      .then(() => {
        refresh();
        notify('Successfully approved', { type: 'info' });
      })
      .catch(error => {
        notify(parseError(error), { type: 'warning' });
      });
  };

  return (
    <Button variant="contained" color="primary" size="small" onClick={handleClick}>
      Approve
    </Button>
  );
};

const ApproveAllButton = ({ selectedIds }) => {
  const [loading, setLoading] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    setLoading(true);

    Promise.all(selectedIds.map(id => axios.post(`/v1/wallets/${id}/verify_identity_document`)))
      .then(() => {
        refresh();
        notify('Successfully approved all', { type: 'info' });
      })
      .catch(error => {
        refresh();
        notify(parseError(error), { type: 'warning' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Button
      disabled={loading}
      variant="contained"
      color="primary"
      size="small"
      onClick={handleClick}
    >
      {loading ? <CircularProgress size="2rem" /> : 'Approve All'}
    </Button>
  );
};

export const WalletList = props => {
  const dispatch = useDispatch();
  const list = useSelector(state => state?.admin?.resources?.['wallets']?.list);

  useEffect(() => {
    batch(() => {
      list.ids.forEach(id => {
        if (list.expanded.includes(id)) return;
        dispatch(toggleListItemExpand('wallets', id));
      });
    });
  }, [dispatch, list.ids, list.expanded]);

  return (
    <List
      {...props}
      bulkActionButtons={<ApproveAllButton />}
      perPage={defaultPerPage}
      filter={{ topLevel: { verification: true } }}
      pagination={<Pagination />}
    >
      <Datagrid expand={<Screenshots />}>
        <UserLinkField source="advertiser" />
        <UserLinkField source="influencer" />
        <TextField source="available_balance" />
        <TextField source="email" />
        <BooleanField source="business" />
        <TextField source="name" />
        <TextField source="country_code" />
        <TextField source="bank_country_code" />
        <TextField source="city" />
        <TextField source="postal_code" />
        <TextField source="address_1" label="Address" />

        <ApproveButton />
      </Datagrid>
    </List>
  );
};

const listFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const AllWalletList = props => (
  <List
    {...props}
    bulkActionButtons={null}
    filters={listFilters}
    filter={{ topLevel: { verification: false } }}
    perPage={defaultPerPage}
    pagination={<Pagination />}
  >
    <Datagrid expand={<Screenshots />}>
      <TextField source="id" />
      <UserLinkField source="advertiser" sortable={false} />
      <UserLinkField source="influencer" sortable={false} />
      <TextField source="available_balance" sortable={false} />
      <TextField source="email" />
      <BooleanField source="business" />
      <TextField source="name" />
      <TextField source="country_code" />
      <TextField source="city" />
      <TextField source="postal_code" />
      <TextField source="address_1" label="Address" />
    </Datagrid>
  </List>
);
