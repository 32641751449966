import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  NumberInput,
  ImageInput,
  Datagrid,
  TextField,
  UrlField,
  NumberField,
  ImageField,
  BooleanField,
  BooleanInput,
} from 'react-admin';

export const NetworkList = props => (
  <List {...props} pagination={false} bulkActionButtons={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <UrlField label="URL" source="url" sortable={false} />
      <NumberField source="order" sortable={false} />
      <BooleanField source="automatic_verification" sortable={false} />
      <ImageField label="Icon" source="icon_url" sortable={false} />
      <ImageField label="Active Icon" source="active_icon_url" sortable={false} />
    </Datagrid>
  </List>
);

export const NetworkEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="name" />
      <TextInput source="url" />
      <NumberInput source="order" />
      <BooleanInput source="automatic_verification" />

      <ImageField source="icon_url" label="Current Icon" />

      <ImageInput source="icon" accept="image/*, svg+xml">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageField source="active_icon_url" label="Current Active Icon" />

      <ImageInput source="active_icon" accept="image/*, svg+xml">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const NetworkCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="url" label="URL" />
      <NumberInput source="order" />
      <BooleanInput source="automatic_verification" />

      <ImageInput source="icon" accept="image/*, svg+xml">
        <ImageField source="src" title="title" />
      </ImageInput>

      <ImageInput source="active_icon" accept="image/*, svg+xml">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);

export const NetworkShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="url" label="URL" />
      <NumberField source="order" />
      <ImageField source="icon_url" />
      <ImageField source="active_icon_url" />
    </SimpleShowLayout>
  </Show>
);
