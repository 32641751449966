import Avatar from '@material-ui/core/Avatar';

const AvatarField = ({ record, source, size = '40', className }) =>
  record ? (
    <Avatar
      src={`${record[source]}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;

export default AvatarField;
