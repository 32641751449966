import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Datagrid,
  TextField,
  ReferenceField,
  AutocompleteInput,
  ReferenceInput,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput
    source="category_id"
    reference="categories"
    filterToQuery={searchText => ({
      q: searchText,
    })}
  >
    <AutocompleteInput fullWidth />
  </ReferenceInput>,
];

export const SubCategoryList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />

      <ReferenceField source="category_id" reference="categories">
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="adult" />
      <TextField source="profiles_count" />
    </Datagrid>
  </List>
);

export const SubCategoryCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/sub_categories">
      <TextInput source="name" />

      <ReferenceInput source="category_id" reference="categories">
        <AutocompleteInput fullWidth optionText={record => record?.name} />
      </ReferenceInput>

      <BooleanInput source="adult" />
    </SimpleForm>
  </Create>
);

export const SubCategoryEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="/sub_categories">
      <TextInput source="name" />

      <ReferenceInput source="category_id" reference="categories">
        <AutocompleteInput fullWidth optionText={record => record?.name} />
      </ReferenceInput>

      <BooleanInput source="adult" />
    </SimpleForm>
  </Edit>
);

export const SubCategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />

      <ReferenceField reference="categories" label="Category" source="category_id">
        <TextField source="name" />
      </ReferenceField>

      <BooleanField source="adult" />
      <TextField source="profiles_count" />
    </SimpleShowLayout>
  </Show>
);
