import axios from 'utils/axios';
import { API_URL, getAccessToken } from 'utils/helpers';
import {
  getAdminUser,
  getTokenFromResponse,
  saveAccessToken,
  saveAdminUser,
  signOut,
} from 'utils/helpers';

const authProvider = {
  login: ({ username, password, otp }) =>
    axios
      .post(`${API_URL}/sign_in`, { user: { login: username, password, otp_attempt: otp } })
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        const token = getTokenFromResponse(response);

        if (token && response.data.role === 'admin') {
          saveAdminUser(response.data);
          saveAccessToken(token);

          return Promise.resolve();
        }

        return Promise.reject();
      }),

  logout: () =>
    axios.delete(`${API_URL}/sign_out`).then(() => {
      signOut();

      return Promise.resolve();
    }),

  checkError: error => {
    const status = error.status || error?.response?.status;

    if (status === 401) {
      signOut();
      return Promise.reject();
    }

    return Promise.resolve();
  },

  checkAuth: () => (getAccessToken() ? Promise.resolve() : Promise.reject()),

  getPermissions: () => {
    const user = getAdminUser();

    return user?.role === 'admin' ? Promise.resolve(user.role) : Promise.reject();
  },

  getIdentity: () => {
    const user = getAdminUser();

    if (user) {
      return Promise.resolve({
        id: user.id,
        fullName: `${user.first_name} ${user.last_name}`,
        avatar: user.avatar_url,
      });
    }

    return Promise.reject();
  },
};

export default authProvider;
