import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  NumberInput,
  ImageInput,
  Datagrid,
  TextField,
  ReferenceInput,
  NumberField,
  SelectInput,
  ImageField,
  BooleanField,
  BooleanInput,
} from 'react-admin';
import Pagination from 'components/Pagination';
import { defaultPerPage } from 'utils/helpers';
import { wishlistOptionKindChoices } from 'choices';

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput source="network_id" reference="networks">
    <SelectInput optionText="name" />
  </ReferenceInput>,
];

export const WishlistOptionList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    bulkActionButtons={false}
    filters={listFilters}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ImageField source="image_url" label="Image" sortable={false} />
      <TextField source="name" />
      <NumberField source="default_price" />
      <TextField source="kind" />
      <BooleanField source="active" />
      <BooleanField source="allow_repeat_purchase" />
    </Datagrid>
  </List>
);

export const WishlistOptionEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="description" multiline required rows={10} />
      <NumberInput source="default_price" helperText="Default price for the wishlist option" />

      <SelectInput source="kind" choices={wishlistOptionKindChoices} />

      <BooleanInput
        source="active"
        helperText="If available to creators when setting up their wishlist"
      />

      <ImageField source="image_url" label="Current Icon" />

      <ImageInput source="image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Edit>
);

export const WishlistOptionCreate = props => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="name" required />
      <TextInput source="description" multiline required rows={10} />
      <NumberInput source="default_price" helperText="Default price for the wishlist option" />

      <SelectInput source="kind" choices={wishlistOptionKindChoices} />

      <ImageInput source="image" accept="image/*">
        <ImageField source="src" title="title" />
      </ImageInput>
    </SimpleForm>
  </Create>
);
