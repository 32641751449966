import Pagination from 'components/Pagination';
import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  ReferenceField,
  SelectInput,
  ReferenceInput,
} from 'react-admin';

const listFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const TraitList = props => (
  <List {...props} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField reference="trait_categories" source="trait_category_id">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export const TraitCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/traits">
      <TextInput source="name" />
      <ReferenceInput source="trait_category_id" reference="trait_categories">
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const TraitEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="/traits">
      <TextInput source="name" />
      <ReferenceInput source="trait_category_id" reference="trait_categories">
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
);
