import {
  payoutMethodAccountTypeChoices,
  payoutMethodHolderTypeChoices,
  payoutMethodKindChoices,
  payoutMethodStatusChoices,
} from 'choices';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  EmailField,
  SelectInput,
  FormDataConsumer,
  Link,
} from 'react-admin';
import { useLinkStyles } from 'fields/PolymorphicLinkField';

export const UserLink = ({ record }) => {
  const styles = useLinkStyles();

  return (
    <Link
      className={styles.link}
      onClick={e => {
        e.stopPropagation();
      }}
      to={`/users/${record?.user_id}`}
    >
      {record?.display_name}
    </Link>
  );
};

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <SelectInput source="kind" choices={payoutMethodKindChoices} alwaysOn />,
  <SelectInput source="status" choices={payoutMethodStatusChoices} alwaysOn />,
];

export const PayoutMethodList = props => (
  <List {...props} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="account_holder" />
      <TextField source="account_number" />
      <TextField source="account_type" />
      <TextField source="crypto_wallet_address" />
      <TextField source="holder_type" />
      <TextField source="kind" />
      <EmailField source="paypal_email" />
      <TextField source="routing_number" />
      <TextField source="status" />
      <UserLink label="User" />
    </Datagrid>
  </List>
);

export const PayoutMethodEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item md={4}>
          <SelectInput fullWidth source="kind" choices={payoutMethodKindChoices} />
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData }) => (
          <Grid container spacing={2} style={{ width: '100%' }}>
            {formData.kind === 'ach' && (
              <>
                <Grid item md={4}>
                  <TextInput fullWidth source="account_holder" />
                </Grid>

                <Grid item md={4}>
                  <TextInput fullWidth source="account_number" />
                </Grid>

                <Grid item md={4}>
                  <TextInput fullWidth source="routing_number" />
                </Grid>

                <Grid item md={6}>
                  <SelectInput
                    fullWidth
                    source="account_type"
                    choices={payoutMethodAccountTypeChoices}
                  />
                </Grid>

                <Grid item md={6}>
                  <SelectInput
                    fullWidth
                    source="holder_type"
                    choices={payoutMethodHolderTypeChoices}
                  />
                </Grid>
              </>
            )}

            {formData.kind === 'paypal' && (
              <Grid item md={4}>
                <TextInput fullWidth source="paypal_email" />
              </Grid>
            )}

            {formData.kind === 'crypto' && (
              <Grid item md={4}>
                <TextInput fullWidth source="crypto_wallet_address" />
              </Grid>
            )}
          </Grid>
        )}
      </FormDataConsumer>

      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item md={4}>
          <SelectInput fullWidth source="status" choices={payoutMethodStatusChoices} />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item md={4}>
          <TextInput source="wallet_id" disabled fullWidth />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);
