import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
} from 'react-admin';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,

  <ReferenceInput
    source="profile_id"
    reference="profiles"
    filterToQuery={searchText => ({
      q: searchText,
    })}
    label="Influencer"
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
      }
    />
  </ReferenceInput>,
];

export const DiscountCodeCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/discount_codes">
      <TextInput source="code" />
      <NumberInput source="amount" />
      <NumberInput source="percentage" />

      <NumberInput source="limit_count" label="Limit count (0 is unlimited)" />
      <DateInput source="starts_at" />
      <DateInput source="expires_at" />

      <ReferenceInput source="profile_id" reference="profiles">
        <AutocompleteInput
          fullWidth
          optionText={record =>
            record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
          }
        />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

export const DiscountCodeEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput source="code" />
      <NumberInput source="amount" />
      <NumberInput source="percentage" />

      <NumberInput source="limit_count" label="Limit count (0 is unlimited)" />
      <DateInput source="starts_at" />
      <DateInput source="expires_at" />

      <ReferenceInput source="profile_id" reference="profiles">
        <AutocompleteInput
          fullWidth
          optionText={record =>
            record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
          }
        />
      </ReferenceInput>

      <DateInput source="created_at" disabled />
    </SimpleForm>
  </Edit>
);

export const DiscountCodeList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />

      <TextField source="code" />
      <NumberField source="amount" />
      <NumberField source="percentage" />

      <ReferenceField source="profile_id" reference="profiles" sortable={false}>
        <TextField source="display_name" />
      </ReferenceField>

      <DateField source="created_at" />
    </Datagrid>
  </List>
);
