export const userStatusColors = {
  active: '#009688', // blue
  suspended: '#ff9800', // orange
  banned: '#ff1744', // red
  deleted: '#ff1744', // red
};

export const roleColors = {
  advertiser: '#2196f3', // blue
  influencer: '#3f51b5', // purple
  affiliate: '#ff9800', // orange
  admin: '#ff1744', // red
};
