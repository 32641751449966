import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  useEditController,
  EditContextProvider,
} from 'react-admin';
import pluralize from 'pluralize';
import { IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router';

const liveTimeFormatter = option => {
  if (!option) return null;

  return (
    <div>
      {option.duration} {pluralize(option.unit, option.duration)}{' '}
      {option.default && '(most requested)'}
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 40,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '1em',
  },
  form: {
    [theme.breakpoints.up('xs')]: {
      width: 400,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100vw',
      marginTop: -30,
    },
  },
  inlineField: {
    display: 'inline-block',
    width: '50%',
  },
}));

export const InventoryItemDrawerEdit = ({ profileId, onCancel, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  const controllerProps = useEditController(props);

  if (!controllerProps.record) return null;

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography variant="h6">Inventory Item Edit</Typography>

        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>

      <EditContextProvider value={controllerProps}>
        <SimpleForm
          className={classes.form}
          basePath={controllerProps.basePath}
          record={controllerProps.record}
          save={(data, redirect) => {
            controllerProps.save(data, false, {
              onSuccess: () => {
                history.push(`/profiles/${profileId}`);
                onCancel();
              },
            });
          }}
          version={controllerProps.version}
          redirect={`/profiles/${profileId}`}
          resource="reviews"
        >
          <NumberInput source="average_views" />
          <NumberInput source="min_guaranteed_views" />
          <TextInput source="price" />

          <FormDataConsumer>
            {({ formData }) => (
              <ReferenceInput
                style={{ width: 256 }}
                label="Live Time"
                source="live_time_option_id"
                reference="live_time_options"
                filter={{
                  topLevel: {
                    inventory_item_id: formData.id,
                  },
                }}
              >
                <SelectInput optionText={liveTimeFormatter} />
              </ReferenceInput>
            )}
          </FormDataConsumer>

          <BooleanInput source="default" />
        </SimpleForm>
      </EditContextProvider>
    </div>
  );
};

export const InventoryItemEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect={(_, __, record) => `/profiles/${record.profile_id}`}>
      <NumberInput source="average_views" />
      <NumberInput source="min_guaranteed_views" />
      <TextInput source="price" />

      <FormDataConsumer>
        {({ formData }) => (
          <ReferenceInput
            style={{ width: 256 }}
            label="Live Time"
            source="live_time_option_id"
            reference="live_time_options"
            filter={{
              topLevel: {
                inventory_item_id: formData.id,
              },
            }}
          >
            <SelectInput optionText={liveTimeFormatter} />
          </ReferenceInput>
        )}
      </FormDataConsumer>

      <BooleanInput source="default" />
    </SimpleForm>
  </Edit>
);
