import React, { useEffect, useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import Fab from '@material-ui/core/Fab';
import SendIcon from '@material-ui/icons/Send';
import { Link, useParams } from 'react-router-dom';

import axios from 'utils/axios';
import Message from 'components/chats/Message';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: '100%',
    maxHeight: 600,
  },
  headBG: {
    backgroundColor: '#e0e0e0',
  },
  borderRight500: {
    borderRight: '1px solid #e0e0e0',
  },
  messageArea: {
    height: 400,
    overflowY: 'scroll',
  },
  inputArea: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
  },
});

const Chat = ({ recipientType, recipientId, conversationType = 'Chat', sidebar = true }) => {
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const inputRef = useRef();
  const { id } = useParams();
  const classes = useStyles();

  const createMessage = () => {
    const body = inputRef.current.value;

    if (!body.length) return;

    const conversationId = recipientType && recipientId ? conversation.id : id;
    const url = `/v1/conversations/${conversationId}/messages?conversation_type=${conversationType}`;

    axios.post(url, { body }).then(response => {
      setMessages(prevState => [...prevState, response.data]);
      inputRef.current.value = '';
    });
  };

  const deleteMessage = id => {
    axios.delete(`/v1/messages/${id}`).then(() => {
      setMessages(prevState => prevState.filter(message => message.id !== id));
    });
  };

  useEffect(() => {
    axios
      .get(
        `/v1/conversations/${id}/messages?conversation_type=${conversationType}&recipient_type=${recipientType}&recipient_id=${recipientId}`
      )
      .then(response => {
        setMessages(response.data);
      });

    if (conversationType === 'Chat') {
      axios
        .get(`/v1/chats/${id}?recipient_type=${recipientType}&recipient_id=${recipientId}`)
        .then(response => {
          setConversation(response.data);
        })
        .catch(() => {
          axios
            .post(`/v1/chats?recipient_type=${recipientType}&recipient_id=${recipientId}`)
            .then(response => setConversation(response.data));
        });
    }
  }, [id, conversationType, recipientId, recipientType]);

  const role = conversation?.recipient?.role || 'influencer';
  const href =
    role === 'influencer'
      ? `/profiles/${conversation?.recipient?.id}`
      : `/users/${conversation?.recipient?.id}`;

  return (
    <Grid container component={Paper} className={classes.chatSection}>
      {sidebar && (
        <Grid item xs={3} className={classes.borderRight500}>
          {conversation && (
            <List>
              <ListItem button to={href} component={Link}>
                <ListItemIcon>
                  <Avatar src={conversation?.recipient?.avatar_url} />
                </ListItemIcon>

                <ListItemText
                  primary={conversation?.recipient?.name || conversation?.recipient?.display_name}
                  secondary={role}
                ></ListItemText>
              </ListItem>
            </List>
          )}
        </Grid>
      )}

      <Grid item xs={sidebar ? 9 : 12}>
        <List className={classes.messageArea}>
          {messages.map(message => (
            <Message key={message.id} message={message} deleteMessage={deleteMessage} />
          ))}
        </List>

        <Divider />

        <Grid container style={{ padding: '20px' }} className={classes.inputArea}>
          <Grid item xs={11}>
            <TextField
              inputRef={inputRef}
              label="Type Something"
              fullWidth
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  createMessage();
                }
              }}
            />
          </Grid>

          <Grid xs={1} align="right">
            <Fab color="primary" aria-label="add" onClick={createMessage}>
              <SendIcon />
            </Fab>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Chat;
