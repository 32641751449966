import {
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  DateField,
  DateInput,
  Labeled,
} from 'react-admin';
import PolymorphicLinkField from 'fields/PolymorphicLinkField';
import { supportRequestStatusChoices } from '../choices';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';

export const SupportRequestList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="message" />

      <ReferenceField source="gig_id" reference="gigs">
        <TextField source="title" />
      </ReferenceField>

      <ReferenceField source="milestone_id" reference="milestones">
        <TextField source="name" />
      </ReferenceField>

      <PolymorphicLinkField source="requester" label="Requester" />

      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const SupportRequestEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <Labeled label="Requester">
        <PolymorphicLinkField source="requester" label="Requester" />
      </Labeled>

      <TextInput source="id" disabled />
      <TextInput source="message" disabled />
      <SelectInput source="status" choices={supportRequestStatusChoices} />

      <ReferenceInput source="gig_id" reference="gigs" disabled>
        <SelectInput optionText="title" />
      </ReferenceInput>

      <ReferenceInput source="milestone_id" reference="milestones" disabled>
        <SelectInput optionText="name" />
      </ReferenceInput>

      <DateInput source="created_at" disabled />
    </SimpleForm>
  </Edit>
);
