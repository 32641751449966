import PolymorphicLinkField from 'fields/PolymorphicLinkField';
import {
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  NumberField,
  NumberInput,
  SelectInput,
  ReferenceInput,
  ReferenceField,
  DateField,
  DateInput,
  AutocompleteInput,
  Labeled,
} from 'react-admin';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,

  <ReferenceInput
    source="topLevel.user_id"
    reference="users"
    filterToQuery={searchText => ({
      q: searchText,
      role: 'advertiser',
    })}
    label="Advertiser"
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.email || record?.phone}>` : ''
      }
    />
  </ReferenceInput>,

  <ReferenceInput
    source="topLevel.profile_id"
    reference="profiles"
    filterToQuery={searchText => ({
      q: searchText,
    })}
    label="Influencer"
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
      }
    />
  </ReferenceInput>,
];

export const ReviewList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <PolymorphicLinkField source="reviewer" label="Reviewer" />

      <ReferenceField source="gig_id" reference="gigs">
        <TextField source="title" />
      </ReferenceField>

      <PolymorphicLinkField source="reviewee" label="Reviewee" />

      <NumberField source="rating" />
      <TextField source="comment" />

      <DateField source="created_at" />
    </Datagrid>
  </List>
);

export const ReviewEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <NumberInput source="rating" />
      <TextInput source="comment" />

      <ReferenceInput source="gig_id" reference="gigs" disabled>
        <SelectInput optionText="title" />
      </ReferenceInput>

      <DateInput source="created_at" disabled />

      <Labeled label="Reviewer">
        <PolymorphicLinkField source="reviewer" />
      </Labeled>

      <Labeled label="Reviewee">
        <PolymorphicLinkField source="reviewee" label="Reviewee" />
      </Labeled>
    </SimpleForm>
  </Edit>
);
