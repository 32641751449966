import { Card, CardContent, CardHeader, Grid, Typography, Button } from '@material-ui/core';
import { audienceGenderChoices, influencerNetworkStatusChoices } from 'choices';
import Pagination from 'components/Pagination';
import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  NumberInput,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
} from 'react-admin';
import fileDownload from 'js-file-download';
import { defaultPerPage } from 'utils/helpers';
import axios from 'utils/axios';

const spaceBetween = { display: 'flex', justifyContent: 'space-between' };
const selectContainer = { marginLeft: 10, marginRight: 10 };
const flexColumn = { display: 'flex', flexDirection: 'column' };
const fieldHalf = { width: '48%' };
const subheaderStyle = { display: 'flex', justifyContent: 'space-between' };
const linkStyle = { color: 'blue', textDecoration: 'underline', cursor: 'pointer' };

const ExportField = () => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .get(`/v1/attachments/export_screenshots_csv`, {
        responseType: 'blob',
      })
      .then(response => {
        fileDownload(response.data, `computer-vision-screenshots-${new Date()}.csv`);
      });
  };

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}
      style={{ bottom: 20 }}
    >
      Export Screenshots
    </Button>
  );
};
const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,

  <SelectInput source="status" choices={influencerNetworkStatusChoices} />,

  <ReferenceInput source="network_id" reference="networks">
    <SelectInput />
  </ReferenceInput>,

  <ReferenceInput
    source="profile_id"
    reference="profiles"
    filterToQuery={searchText => ({
      q: searchText,
    })}
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
      }
    />
  </ReferenceInput>,

  <ExportField alwaysOn />,
];

export const InfluencerNetworkList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    filters={listFilters}
    exporter={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <ReferenceField source="profile_id" reference="profiles">
        <TextField source="display_name" />
      </ReferenceField>

      <ReferenceField source="network_id" reference="networks">
        <TextField source="name" />
      </ReferenceField>

      <TextField source="username" />
      <TextField source="status" />

      <NumberField source="followers" />
      <NumberField source="fans" />
      <NumberField source="top_creator_percentage" />
      <NumberField source="subscribers" />

      <DateField source="created_at" />
    </Datagrid>
  </List>
);

const SocialInfoAside = ({ record }) => (
  <Card style={{ minWidth: 300, marginLeft: 20 }}>
    <CardHeader title="Social Info" />

    <CardContent style={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="strong">
        Gig Social Profile{' '}
        <a href={`/profiles/${record?.profile_id}`} target="_blank" rel="noreferrer">
          Link
        </a>
      </Typography>

      <Typography component="strong">
        {record?.network?.name} Profile{' '}
        <a href={`${record?.network?.url}${record?.username}`} target="_blank" rel="noreferrer">
          Link
        </a>
      </Typography>

      <br />

      {record?.profile_screenshot_url && (
        <>
          <Typography component="strong" style={subheaderStyle}>
            Profile Screenshot:
            <a style={linkStyle} href={record.profile_screenshot_download_url} alt="download">
              Download
            </a>
          </Typography>

          <small>Click the image to open in a new tab</small>
          <br />

          <a href={record?.profile_screenshot_url} target="_blank" rel="noreferrer">
            <ImageField source="profile_screenshot_url" record={record} />
          </a>
        </>
      )}

      <br />

      {record?.location_screenshot_url && (
        <>
          <Typography component="strong" style={subheaderStyle}>
            Location Screenshot:
            <a style={linkStyle} href={record.location_screenshot_download_url} alt="download">
              Download
            </a>
          </Typography>

          <small>Click the image to open in a new tab</small>
          <br />

          <a href={record?.profile_screenshot_url} target="_blank" rel="noreferrer">
            <ImageField source="location_screenshot_url" record={record} />
          </a>
        </>
      )}

      <br />

      {record?.gender_screenshot_url && (
        <>
          <Typography component="strong" style={subheaderStyle}>
            Gender Screenshot:
            <a style={linkStyle} href={record.gender_screenshot_download_url} alt="download">
              Download
            </a>
          </Typography>

          <small>Click the image to open in a new tab</small>
          <br />

          <a href={record?.profile_screenshot_url} target="_blank" rel="noreferrer">
            <ImageField source="gender_screenshot_url" record={record} />
          </a>
        </>
      )}

      {record?.analytics_screenshot_url && (
        <>
          <Typography component="strong" style={subheaderStyle}>
            Analytics Screenshot:
            <a style={linkStyle} href={record.analytics_screenshot_download_url} alt="download">
              Download
            </a>
          </Typography>

          <small>Click the image to open in a new tab</small>
          <br />

          <a href={record?.analytics_screenshot_url} target="_blank" rel="noreferrer">
            <ImageField source="analytics_screenshot_url" record={record} />
          </a>
        </>
      )}
    </CardContent>
  </Card>
);

export const InfluencerNetworkEdit = props => (
  <>
    <Edit aside={<SocialInfoAside />} {...props} mutationMode="pessimistic">
      <SimpleForm>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item md={6}>
            <div style={selectContainer}>
              <ReferenceInput source="profile_id" reference="profiles">
                <AutocompleteInput
                  fullWidth
                  optionText={record =>
                    record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
                  }
                />
              </ReferenceInput>

              <ReferenceInput source="network_id" reference="networks">
                <SelectInput fullWidth />
              </ReferenceInput>
            </div>

            <div style={selectContainer}>
              <div style={spaceBetween}>
                <SelectInput
                  source="popular_gender"
                  choices={audienceGenderChoices}
                  style={fieldHalf}
                />
                <NumberInput source="gender_percentage" min={1} max={100} style={fieldHalf} />
              </div>

              <div style={spaceBetween}>
                <NumberInput fullWidth source="followers" style={fieldHalf} />
                <NumberInput fullWidth source="following" style={fieldHalf} />
              </div>

              <div style={spaceBetween}>
                <NumberInput source="posts" style={fieldHalf} />
                <NumberInput source="likes" style={fieldHalf} />
              </div>
            </div>
          </Grid>

          <Grid item md={6} style={flexColumn}>
            <TextInput source="username" />
            <SelectInput source="status" choices={influencerNetworkStatusChoices} />

            <div style={spaceBetween}>
              <NumberInput source="tracks" style={fieldHalf} />
              <NumberInput source="monthly_listeners" style={fieldHalf} />
            </div>

            <div style={spaceBetween}>
              <NumberInput source="fans" style={fieldHalf} />
              <NumberInput source="subscribers" style={fieldHalf} />
            </div>

            <div style={spaceBetween}>
              <NumberInput source="top_creator_percentage" style={fieldHalf} />
            </div>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  </>
);

export const InfluencerNetworkCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item md={6}>
          <div style={selectContainer}>
            <ReferenceInput source="profile_id" reference="profiles">
              <AutocompleteInput
                fullWidth
                optionText={record =>
                  record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
                }
              />
            </ReferenceInput>

            <ReferenceInput source="network_id" reference="networks">
              <SelectInput fullWidth />
            </ReferenceInput>
          </div>

          <div style={selectContainer}>
            <div style={spaceBetween}>
              <SelectInput
                source="popular_gender"
                choices={audienceGenderChoices}
                style={fieldHalf}
              />
              <NumberInput source="gender_percentage" min={1} max={100} style={fieldHalf} />
            </div>

            <div style={spaceBetween}>
              <NumberInput fullWidth source="followers" style={fieldHalf} />
              <NumberInput fullWidth source="following" style={fieldHalf} />
            </div>

            <div style={spaceBetween}>
              <NumberInput source="posts" style={fieldHalf} />
              <NumberInput source="likes" style={fieldHalf} />
            </div>
          </div>
        </Grid>

        <Grid item md={6} style={flexColumn}>
          <TextInput source="username" />
          <SelectInput source="status" choices={influencerNetworkStatusChoices} />

          <div style={spaceBetween}>
            <NumberInput source="tracks" style={fieldHalf} />
            <NumberInput source="monthly_listeners" style={fieldHalf} />
          </div>

          <div style={spaceBetween}>
            <NumberInput source="fans" style={fieldHalf} />
            <NumberInput source="subscribers" style={fieldHalf} />
          </div>

          <div style={spaceBetween}>
            <NumberInput source="top_creator_percentage" style={fieldHalf} />
          </div>
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);
