import { paymentMethodChoices, paymentStatusChoices } from 'choices';
import Pagination from 'components/Pagination';
import {
  Edit,
  List,
  SimpleForm,
  TextInput,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { Button } from '@material-ui/core';
import { JsonField } from 'react-admin-json-view';
import { defaultPerPage } from 'utils/helpers';
import fileDownload from 'js-file-download';

import { PayableField } from '../fields/PolymorphicLinkField';

import axios from 'utils/axios';

const ReceiptField = ({ record }) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .get(`/v1/payments/${record.id}/receipt_pdf`, {
        responseType: 'blob',
      })
      .then(response => {
        fileDownload(response.data, `Payment-${record.id}-gig-social-receipt.pdf`);
      });
  };

  return (
    <Button color="primary" variant="contained" size="small" onClick={handleClick}>
      Receipt
    </Button>
  );
};

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,

  <ReferenceInput
    source="user_id"
    reference="users"
    label="Advertiser"
    filterToQuery={searchText => ({
      q: searchText,
    })}
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.email || record?.phone}>` : ''
      }
    />
  </ReferenceInput>,

  <SelectInput source="status" choices={paymentStatusChoices} />,
  <SelectInput source="payment_method" choices={paymentMethodChoices} />,
];

export const PaymentList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    filters={listFilters}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <PayableField label="Payable" />

      <TextField source="payment_method" />
      <TextField source="status" />
      <TextField source="auth_amount" />
      <TextField source="capture_amount" />

      <DateField source="created_at" />

      <TextField source="authorization_id" label="Authorization ID" />
      <TextField source="capture_id" label="Capture ID" />
      <TextField source="nmi_transaction_id" label="NMI Transaction ID" />

      <ReferenceField source="user_id" reference="users">
        <TextField source="display_name" />
      </ReferenceField>

      <ReceiptField label="Receipt PDF" />
    </Datagrid>
  </List>
);

export const PaymentEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <TextInput source="transaction_id" disabled />

      <JsonField source="auth_data" />
      <TextInput source="auth_amount" disabled />
      <JsonField source="capture_data" disabled />
      <TextInput source="capture_amount" disabled />
    </SimpleForm>
  </Edit>
);
