import { useState } from 'react';
import { gigStatusChoices } from 'choices';
import {
  Create,
  Edit,
  List,
  SimpleForm,
  TextInput,
  ImageInput,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  BooleanField,
  BooleanInput,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  DateInput,
  useNotify,
} from 'react-admin';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import fileDownload from 'js-file-download';

import { LiveTimeField } from 'resources/milestones';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';
import axios from 'utils/axios';

const ReceiptField = ({ record }) => {
  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .get(`/v1/gigs/${record.id}/receipt_pdf`, {
        responseType: 'blob',
      })
      .then(response => {
        fileDownload(response.data, `Gig-${record.id}-gig-social-receipt.pdf`);
      });
  };

  if (!record.profile_id) return null;

  return (
    <Button color="primary" variant="contained" size="small" onClick={handleClick}>
      Receipt
    </Button>
  );
};

const CancelAndRefundField = ({ record }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = e => {
    setLoading(true);

    e.preventDefault();
    e.stopPropagation();

    axios
      .post(`/v1/gigs/${record.id}/cancel`)
      .then(() => {
        notify('Successfully cancelled the gig.', { type: 'info' });

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch(() => {
        notify('Error cancelling the gig.', { type: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (record.status !== 'active') return null;

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}
      disabled={loading}
    >
      Cancel & Refund
    </Button>
  );
};

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,

  <ReferenceInput
    source="user_id"
    reference="users"
    filterToQuery={searchText => ({
      q: searchText,
    })}
    label="Advertiser"
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.email || record?.phone}>` : ''
      }
    />
  </ReferenceInput>,

  <ReferenceInput
    source="profile_id"
    reference="profiles"
    label="Influencer"
    filterToQuery={searchText => ({
      q: searchText,
    })}
  >
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
      }
    />
  </ReferenceInput>,
];

export const GigList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="status" />
      <BooleanField source="public" />

      <ReferenceField source="user_id" reference="users" label="Advertiser" sortable={false}>
        <TextField source="display_name" />
      </ReferenceField>

      <ReferenceField source="profile_id" reference="profiles" label="Influencer" sortable={false}>
        <TextField source="display_name" />
      </ReferenceField>

      <NumberField source="budget" />
      <NumberField source="advertiser_fee_amount" />
      <NumberField source="total_amount" />
      <NumberField source="amount" />
      <NumberField source="influencer_fee_amount" />

      <ReceiptField label="Receipt PDF" />

      <DateField source="created_at" />
    </Datagrid>
  </List>
);

const MilestoneList = ({ gigId }) => (
  <List
    basePath="/milestones"
    resource="milestones"
    perPage={25}
    filter={{ gig_id: gigId }}
    actions={false}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="status" />
      <TextField source="amount" />
      <TextField source="fee_amount" />
      <TextField source="total_amount" />

      <ReferenceField source="gig_id" reference="gigs" label="Gig">
        <TextField source="title" />
      </ReferenceField>

      <ReferenceField source="network_id" reference="networks" label="Network">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        source="inventory_space_id"
        reference="inventory_spaces"
        label="Inventory Space"
      >
        <TextField source="name" />
      </ReferenceField>

      <LiveTimeField label="Live time" />

      <DateField source="delivery_by" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

const PaymentList = props => {
  const payable_id = props.record?.public ? props.id : props.record?.cart_id;
  const payable_type = props.record?.public ? 'Gig' : 'Cart';

  return (
    <List
      basePath="/payments"
      resource="payments"
      perPage={25}
      filter={{ payable_id, payable_type }}
      actions={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />

        <TextField source="payment_method" />
        <TextField source="status" />
        <TextField source="auth_amount" />
        <TextField source="capture_amount" />

        <DateField source="created_at" />

        <TextField source="authorization_id" label="Authorization ID" />
        <TextField source="capture_id" label="Capture ID" />
        <TextField source="transaction_id" label="Transaction ID" />

        <ReferenceField source="user_id" reference="users">
          <TextField source="display_name" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};

const EditLayout = props => (
  <>
    <Paper>
      <Typography
        variant="h6"
        style={{ padding: 20, display: 'flex', justifyContent: 'space-between' }}
      >
        Gig
        <CancelAndRefundField {...props} />
      </Typography>

      <Typography variant="h6" style={{ paddingLeft: 20 }}>
        Influencer:{' '}
        <ReferenceField source="profile_id" reference="profiles" label="Influencer">
          <TextField source="display_name" style={{ fontSize: 14 }} />
        </ReferenceField>
        <br />
        Advertiser:{' '}
        <ReferenceField source="user_id" reference="users" label="Advertiser">
          <TextField source="display_name" style={{ fontSize: 14 }} />
        </ReferenceField>
      </Typography>

      <SimpleForm {...props}>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item md={6}>
            <TextInput fullWidth source="title" />
            <TextInput fullWidth source="description" multiline />
            <SelectInput fullWidth source="status" choices={gigStatusChoices} />

            <BooleanInput fullWidth source="public" disabled />

            <ImageField source="thumbnail_url" label="Current Thumbnail" />

            <ImageInput source="thumbnail" accept="image/*, svg+xml" label="Thumbnail">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>

          <Grid item md={6}>
            <TextInput fullWidth source="budget" disabled={props?.record?.funded} />
            <TextInput fullWidth source="advertiser_fee_amount" disabled />
            <TextInput fullWidth source="influencer_fee_amount" disabled />
            <TextInput fullWidth source="total_amount" disabled />
            <TextInput fullWidth source="amount" disabled />
            <DateInput fullWidth source="expires_at" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Paper>

    <Paper style={{ marginTop: 40 }}>
      <Typography variant="h6" style={{ padding: 20 }}>
        Milestones
      </Typography>

      <MilestoneList gigId={props?.record?.id} />
    </Paper>

    <Paper style={{ marginTop: 40 }}>
      <Typography variant="h6" style={{ padding: 20 }}>
        Payment
      </Typography>

      <PaymentList {...props} />
    </Paper>
  </>
);

export const GigEdit = props => (
  <Edit component="div" {...props} mutationMode="pessimistic">
    <EditLayout {...props} />
  </Edit>
);

export const GigCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" />
      <TextInput source="description" multiline />
      <SelectInput source="status" choices={gigStatusChoices} />
      <TextInput source="budget" disabled={props?.record?.funded} />

      <ReferenceInput source="user_id" reference="users" label="Advertiser">
        <AutocompleteInput
          fullWidth
          optionText={record =>
            record?.display_name
              ? `${record?.display_name} <${record?.email || record?.phone}>`
              : ''
          }
        />
      </ReferenceInput>

      <ReferenceInput
        source="profile_id"
        reference="profiles"
        label="Influencer"
        filterToQuery={searchText => ({
          q: searchText,
        })}
        allowEmpty
      >
        <AutocompleteInput
          fullWidth
          optionText={record =>
            record?.display_name ? `${record?.display_name} <${record?.username}>` : ''
          }
        />
      </ReferenceInput>

      <ImageField source="thumbnail_url" label="Current Thumbnail" />

      <ImageInput source="thumbnail" accept="image/*, svg+xml" label="Thumbnail">
        <ImageField source="src" title="title" />
      </ImageInput>

      <DateInput source="expires_at" />
    </SimpleForm>
  </Create>
);
