import {
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  Datagrid,
  TextField,
  NumberField,
  ReferenceArrayField,
  BooleanField,
  BooleanInput,
} from 'react-admin';

const listFilters = [<TextInput label="Search" source="q" alwaysOn />];

export const CategoryList = props => (
  <List {...props} pagination={false} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="adult" />
      <TextField source="profiles_count" />
      <TextField source="gigs_count" />
    </Datagrid>
  </List>
);

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm redirect="/categories">
      <TextInput source="name" />
      <BooleanInput source="adult" />
    </SimpleForm>
  </Create>
);

export const CategoryEdit = props => (
  <Edit {...props} mutationMode="pessimistic">
    <SimpleForm redirect="/categories">
      <TextInput source="name" />
      <BooleanInput source="adult" />
    </SimpleForm>
  </Edit>
);

export const CategoryShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="adult" />

      <ReferenceArrayField
        reference="sub_categories"
        label="Sub Categories"
        source="sub_category_ids"
      >
        <Datagrid rowClick="edit">
          <NumberField source="id" />
          <NumberField source="name" />
        </Datagrid>
      </ReferenceArrayField>
    </SimpleShowLayout>
  </Show>
);
