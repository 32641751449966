import { TextField } from '@material-ui/core';

const TextArrayInput = ({ record, source, label, inputRef, ...rest }) => {
  if (!record) return null;

  const defaultValue = Array.isArray(record[source]) ? record[source].join(', ') : '';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <TextField
        inputRef={inputRef}
        defaultValue={defaultValue}
        label={label}
        {...rest}
        style={{ marginBottom: 10 }}
      />
    </div>
  );
};

export default TextArrayInput;
