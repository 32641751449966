import { gigInvitationStatusChoices } from 'choices';
import Pagination from 'components/Pagination';
import { differenceInHours } from 'date-fns';
import { List, Datagrid, TextField, ReferenceField, DateField, SelectInput } from 'react-admin';
import { defaultPerPage } from 'utils/helpers';

const listFilters = [
  <SelectInput alwaysOn source="status" choices={gigInvitationStatusChoices} value="pending" />,
];

const ExpiresDiff = ({ record }) => {
  const { created_at, expires_at } = record;

  if (!created_at || !expires_at || record.status !== 'pending') return ' / ';

  const rec = { diff: `${differenceInHours(new Date(expires_at), new Date())} hr` };

  return <TextField record={rec} source="diff" />;
};

const StatusField = ({ record }) => {
  const { status, rejection_reason } = record;

  let newStatus = status;

  if (rejection_reason && rejection_reason.toLowerCase().includes('expired')) {
    newStatus = 'expired';
  }

  return <TextField record={{ status: newStatus }} source="status" />;
};

export const GigInvitationList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    filters={listFilters}
    filterDefaultValues={{ status: 'pending' }}
    sort={{ field: 'expires_at', order: 'ASC' }}
    bulkActionButtons={false}
    exporter={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <ReferenceField source="profile.id" reference="profiles" label="Profile">
        <TextField source="display_name" />
      </ReferenceField>

      <ReferenceField source="gig.user.id" reference="users" label="Advertiser">
        <TextField source="display_name" />
      </ReferenceField>

      <ReferenceField source="gig.id" reference="gigs" label="Gig">
        <TextField source="title" />
      </ReferenceField>

      <StatusField label="Status" source="status" />
      <DateField source="created_at" showTime />
      <DateField source="expires_at" showTime />
      <DateField source="viewed_at" showTime />
      <ExpiresDiff label="Remaining" />
    </Datagrid>
  </List>
);
