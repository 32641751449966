import {
  Edit,
  List,
  SimpleForm,
  ArrayField,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  ReferenceInput,
  ReferenceField,
  AutocompleteInput,
  SelectInput,
} from 'react-admin';
import { Typography, Paper } from '@material-ui/core';
import pluralize from 'pluralize';
import { cartStatusChoices } from 'choices';
import { UserInfo } from 'resources/profiles';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';

const listFilters = [
  <ReferenceInput source="topLevel.user_id" reference="users" label="Advertiser">
    <AutocompleteInput
      fullWidth
      optionText={record =>
        record?.display_name ? `${record?.display_name} <${record?.email || record?.phone}>` : ''
      }
      filterToQuery={searchText => ({
        q: searchText,
        role: 'advertiser',
      })}
    />
  </ReferenceInput>,
];

export const CartList = props => (
  <List
    {...props}
    perPage={defaultPerPage}
    pagination={<Pagination />}
    filters={listFilters}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="status" />

      <ReferenceField source="user_id" reference="users" label="Advertiser">
        <TextField source="display_name" />
      </ReferenceField>

      <DateField source="created_at" />
    </Datagrid>
  </List>
);

const InventoryField = ({ record }) =>
  `${record?.influencer_network?.network?.name} ${record?.inventory_space?.name}`;

const InventoryItemField = ({ record }) => (
  <Typography component="span" variant="body2">
    <ReferenceField link="" source="inventory_id" reference="inventories">
      <InventoryField />
    </ReferenceField>{' '}
    ({record.live_time_duration} {pluralize(record.live_time_unit, record.live_time_duration)})
  </Typography>
);

export const CartItems = props => (
  <ArrayField {...props} source="cart_items">
    <Datagrid rowClick={null}>
      <NumberField source="id" label="ID" />
      <NumberField source="quantity" />
      <TextField source="frequency" />
      <TextField source="inventory_item.price" label="Price" />

      <ReferenceField source="inventory_item.id" reference="inventory_items" label="Inventory Item">
        <InventoryItemField />
      </ReferenceField>

      <ReferenceField source="profile.id" reference="profiles" label="Influencer">
        <TextField source="display_name" />
      </ReferenceField>
    </Datagrid>
  </ArrayField>
);

const PaymentList = props => (
  <List
    basePath="/payments"
    resource="payments"
    perPage={25}
    filter={{ payable_id: props.id, payable_type: 'Cart' }}
    actions={false}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />

      <TextField source="payment_method" />
      <TextField source="status" />
      <TextField source="auth_amount" />
      <TextField source="capture_amount" />

      <DateField source="created_at" />

      <TextField source="authorization_id" label="Authorization ID" />
      <TextField source="capture_id" label="Capture ID" />
      <TextField source="transaction_id" label="Transaction ID" />

      <ReferenceField source="user_id" reference="users">
        <TextField source="display_name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

const EditLayout = props => (
  <>
    <ReferenceField label="User" source="user_id" reference="users">
      <UserInfo />
    </ReferenceField>

    <Paper>
      <Typography variant="h6" style={{ padding: 20 }}>
        Cart
      </Typography>
      <SimpleForm {...props}>
        <SelectInput source="status" choices={cartStatusChoices} />
      </SimpleForm>
    </Paper>

    <Paper style={{ marginTop: 20 }}>
      <Typography variant="h6" style={{ padding: 20 }}>
        Cart Items
      </Typography>

      <CartItems />
    </Paper>

    <Paper style={{ marginTop: 20 }}>
      <Typography variant="h6" style={{ padding: 20 }}>
        Payment
      </Typography>

      <PaymentList {...props} />
    </Paper>
  </>
);

export const CartEdit = props => (
  <Edit component="div" {...props} mutationMode="pessimistic">
    <EditLayout {...props} />
  </Edit>
);
