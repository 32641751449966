import { Fragment } from 'react';
import {
  Show,
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  useRecordContext,
  Link,
  ChipField,
  useNotify,
  createMuiTheme,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import { useHistory } from 'react-router-dom';

import { Grid, Paper, Button } from '@material-ui/core';
import { defaultPerPage } from 'utils/helpers';
import Pagination from 'components/Pagination';
import { UserInfo } from 'resources/profiles';
import { CartItems } from 'resources/carts';
import axios from 'utils/axios';
import { teal, red } from '@material-ui/core/colors';
import { ThemeProvider } from '@material-ui/core/styles';

export const PaymentRiskDetailsList = props => (
  <>
    <List
      {...props}
      perPage={defaultPerPage}
      pagination={<Pagination />}
      filterDefaultValues={{ topLevel: { completed: true } }}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <DateField source="created_at" />
        <NumberField source="score" />
        <TextField source="payable_type" />
        <TextField source="payable_id" />
      </Datagrid>
    </List>
  </>
);

const theme = createMuiTheme({
  palette: {
    primary: teal,
    secondary: red,
  },
});

const RiskInfo = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const history = useHistory();

  if (!record?.buyer) return null;

  const approve = () => {
    axios
      .post(`/v1/payment_risk_details/${record.id}/approve`)
      .then(() => {
        notify('Payment approved', 'info');
        history.push('/payment_risk_details');
      })
      .catch(e => {
        console.log('e', e);
        notify('Error approving payment', 'error');
      });
  };

  const deny = () => {
    axios
      .post(`/v1/payment_risk_details/${record.id}/deny`)
      .then(() => {
        notify('Payment denied', 'info');
        history.push('/payment_risk_details');
      })
      .catch(() => {
        notify('Error denying payment', 'error');
      });
  };

  const ban = () => {
    axios
      .post(`/v1/payment_risk_details/${record.id}/ban`)
      .then(() => {
        notify('Payment denied and user banned.', 'info');
        history.push('/payment_risk_details');
      })
      .catch(() => {
        notify('Error banning user', 'error');
      });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Paper>
          <Link to={`/users/${record.buyer.id}/edit`}>
            <UserInfo label="Buyer:" record={record.buyer} />
          </Link>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper>
          <Link to={`/users/${record.seller.id}/edit`}>
            <UserInfo label="Seller:" record={record.seller} withProfile />
          </Link>
        </Paper>
      </Grid>

      <Grid item xs={5}>
        <Paper style={{ padding: 15 }}>
          <span style={{ fontSize: 16 }}>Amount:</span>{' '}
          <NumberField source="payment.auth_amount" color="primary" style={{ fontSize: 16 }} />
          <br />
          <br />
          <span style={{ fontSize: 16 }}>Score:</span>
          <NumberField source="score" color="primary" style={{ fontSize: 16 }} />
          <br />
          <br />
          <span style={{ fontSize: 16 }}>NMI Tranaction ID:</span>{' '}
          <NumberField
            source="payment.nmi_transaction_id"
            color="primary"
            style={{ fontSize: 16 }}
          />
          <br />
          <br />
          <span style={{ fontSize: 16 }}>MaxMind Warnings: </span>
          <br />
          <br />
          {record.warnings.map((warning, index) => (
            <div>
              <ChipField color="primary" key={index} record={{ warning }} source="warning" />
            </div>
          ))}
          <br />
          <br />
          <span style={{ fontSize: 16 }}>MaxMind Data: </span>
          <br />
          <br />
          <JsonField
            reactJsonOptions={{
              collapsed: false,
              displayDataTypes: false,
            }}
            source="data"
            record={record}
            disabled
          />{' '}
          <br />
        </Paper>
      </Grid>

      <Grid item xs={7}>
        <Paper>
          <CartItems record={record.payable} />
        </Paper>

        <div
          style={{
            marginTop: 40,
            display: 'flex',
            gap: 20,
            justifyContent: 'space-between',
            flex: 1,
          }}
        >
          <ThemeProvider theme={theme}>
            <Button variant="contained" color="primary" onClick={approve}>
              Approve
            </Button>

            <Button variant="contained" color="default" onClick={deny}>
              Deny
            </Button>

            <Button variant="contained" color="secondary" onClick={ban}>
              Ban
            </Button>
          </ThemeProvider>
        </div>
      </Grid>
    </Grid>
  );
};

export const PaymentRiskDetailShow = props => (
  <Show {...props} title="Review Payment" component={Fragment}>
    <RiskInfo />
  </Show>
);
