export const uploadTypeChoices = [
  { id: 'asset', name: 'Photo/Video/Audio Upload Asset' },
  { id: 'caption', name: 'Caption' },
  { id: 'url', name: 'URL' },
  { id: 'brand', name: 'Brand Guidelines Upload' },
];

export const genderChoices = [
  { id: 'Straight female', name: 'Straight female' },
  { id: 'Straight male', name: 'Straight male' },
  { id: 'Gay female', name: 'Gay female' },
  { id: 'Gay male', name: 'Gay male' },
  { id: 'Trans female', name: 'Trans female' },
  { id: 'Trans male', name: 'Trans male' },
  { id: 'Bisexual male', name: 'Bisexual male' },
  { id: 'Bisexual female', name: 'Bisexual female' },
  { id: 'Non-binary', name: 'Non-binary' },
];

export const audienceGenderChoices = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
];

export const roleChoices = [
  { id: 'influencer', name: 'Influencer' },
  { id: 'advertiser', name: 'Advertiser' },
  { id: 'affiliate', name: 'Affiliate' },
  { id: 'admin', name: 'Admin' },
  { id: 'school_admin', name: 'School Admin' },
];

export const influencerNetworkStatusChoices = [
  { id: 'pending_verification', name: 'Pending Verification' },
  { id: 'unverified', name: 'Unverified' },
  { id: 'verified', name: 'Verified' },
  { id: 'rejected', name: 'Rejected' },
];

export const liveTimeUnitChoices = [
  { id: 'second', name: 'Second' },
  { id: 'minute', name: 'Minute' },
  { id: 'hour', name: 'Hour' },
  { id: 'day', name: 'Day' },
  { id: 'week', name: 'Week' },
  { id: 'month', name: 'Month' },
  { id: 'year', name: 'Year' },
  { id: 'permanent', name: 'Permanent' },
  { id: 'time', name: 'Time' },
];

export const gigStatusChoices = [
  { id: 'draft', name: 'Draft' },
  { id: 'pending', name: 'Pending' },
  { id: 'active', name: 'Active' },
  { id: 'archived', name: 'Archived' },
  { id: 'completed', name: 'Completed' },
  { id: 'cancelled', name: 'Cancelled' },
];

export const milestoneStatusChoices = [
  { id: 'draft', name: 'Draft' },
  { id: 'pending', name: 'Pending' },
  { id: 'awaiting_approval', name: 'Awaiting Approval' },
  { id: 'approved', name: 'Approved' },
  { id: 'rejected', name: 'Rejected' },
];

export const cartStatusChoices = [
  { id: 'current', name: 'Current' },
  { id: 'pending', name: 'Pending' },
  { id: 'processed', name: 'Processed' },
  { id: 'archived', name: 'Archived' },
];

export const paymentStatusChoices = [
  { id: 'pending', name: 'Pending' },
  { id: 'authorized', name: 'Authorized' },
  { id: 'captured', name: 'Captured' },
  { id: 'failed', name: 'Failed' },
  { id: 'voided', name: 'Voided' },
];

export const paymentMethodChoices = [
  { id: 'card', name: 'Credit Card' },
  { id: 'apple_pay', name: 'Apple Pay' },
  { id: 'google_pay', name: 'Google Pay' },
];

export const userStatusChoices = [
  { id: 'active', name: 'Active' },
  { id: 'suspended', name: 'Suspended' },
  { id: 'banned', name: 'Banned' },
];

export const supportRequestStatusChoices = [
  { id: 'pending', name: 'Pending' },
  { id: 'resolved', name: 'Resolved' },
];

export const payoutMethodKindChoices = [
  { id: 'ach', name: 'ACH' },
  { id: 'paypal', name: 'PayPal' },
  { id: 'crypto', name: 'Crypto' },
];

export const payoutMethodAccountTypeChoices = [
  { id: 'checking', name: 'Checking' },
  { id: 'savings', name: 'Savings' },
];

export const payoutMethodHolderTypeChoices = [
  { id: 'personal', name: 'Personal' },
  { id: 'business', name: 'Business' },
];

export const payoutMethodStatusChoices = [
  { id: 'approved', name: 'Approved' },
  { id: 'pending', name: 'Pending' },
  { id: 'rejected', name: 'Rejected' },
];

export const payoutStatusChoices = [
  { id: 'draft', name: 'Draft' },
  { id: 'inititated', name: 'Inititated' },
  { id: 'completed', name: 'Completed' },
];

export const gigInvitationStatusChoices = [
  { id: 'accepted', name: 'Accepted' },
  { id: 'pending', name: 'Pending' },
  { id: 'declined', name: 'Declined/Expired' },
];

export const notificationTypes = [
  { id: 'sms', name: 'SMS' },
  { id: 'whatsapp', name: 'WhatsApp' },
];

export const primaryInterestChoices = [
  { id: 'buy', name: 'Buy' },
  { id: 'sell', name: 'Sell' },
];

export const wishlistOptionKindChoices = [
  { id: 'single_wish', name: 'Single Wish' },
  { id: 'crowdfunding', name: 'Crowdfunding' },
  // { id: 'subscription', name: 'Subscription' },
];

export const announcementKindOptions = [
  { id: 'influencer', name: 'Influencer' },
  { id: 'advertiser', name: 'Advertiser' },
];
