import { ChipField } from 'react-admin';

const TextArrayField = ({ record, source }) => {
  const arr = record[source];

  if (Array.isArray(arr)) {
    return arr.map((_, idx) => <ChipField key={idx} record={arr} source={`${idx}`} />);
  }

  return null;
};

export default TextArrayField;
