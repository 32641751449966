import { milestoneStatusChoices } from 'choices';
import Pagination from 'components/Pagination';
import fileDownload from 'js-file-download';
import pluralize from 'pluralize';
import Button from '@material-ui/core/Button';
import {
  Edit,
  List,
  SimpleForm,
  TextInput,
  NumberInput,
  Datagrid,
  TextField,
  ImageField,
  DateInput,
  ReferenceField,
  DateField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput,
  DateTimeInput,
  ArrayField,
  SingleFieldList,
  useListContext,
  BooleanInput,
} from 'react-admin';
import { defaultPerPage } from 'utils/helpers';
import axios from 'utils/axios';
import QueryString from 'qs';

const ExportField = props => {
  const {
    filterValues: { topLevel, ...rest },
  } = useListContext(props);

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();

    axios
      .get(`/v1/milestones/export_csv?${QueryString.stringify({ filter: rest, ...topLevel })}`, {
        responseType: 'blob',
      })
      .then(response => {
        fileDownload(response.data, `milestone-views-${new Date()}.csv`);
      });
  };

  return (
    <Button
      color="primary"
      variant="contained"
      size="small"
      onClick={handleClick}
      style={{ bottom: 20 }}
    >
      Export Price & Views
    </Button>
  );
};

const listFilters = [
  <SelectInput source="status" choices={milestoneStatusChoices} alwaysOn />,

  <ReferenceInput source="network_id" reference="networks" alwaysOn>
    <SelectInput />
  </ReferenceInput>,

  <BooleanInput source="topLevel.with_views" label="With Views" alwaysOn />,

  <ReferenceInput
    source="inventory_space_id"
    reference="inventory_spaces"
    filterToQuery={searchText => ({
      q: searchText,
    })}
  >
    <AutocompleteInput fullWidth optionText={record => record?.name} />
  </ReferenceInput>,

  <ReferenceInput
    source="gig_id"
    reference="gigs"
    filterToQuery={searchText => ({
      q: searchText,
    })}
  >
    <AutocompleteInput fullWidth optionText={record => record?.title} />
  </ReferenceInput>,

  <ExportField alwaysOn />,
];

export const LiveTimeField = ({ record }) => (
  <span>
    {record.live_time_duration} {pluralize(record.live_time_unit, record.live_time_duration)}
  </span>
);

export const MilestoneList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="status" />
      <TextField source="total_amount" />
      <TextField source="amount" />
      <TextField source="fee_amount" />

      <ReferenceField source="gig_id" reference="gigs" label="Gig">
        <TextField source="title" />
      </ReferenceField>

      <ReferenceField source="network_id" reference="networks" label="Network">
        <TextField source="name" />
      </ReferenceField>

      <ReferenceField
        source="inventory_space_id"
        reference="inventory_spaces"
        label="Inventory Space"
      >
        <TextField source="name" />
      </ReferenceField>

      <LiveTimeField label="Live time" />

      <DateField source="delivery_by" />
      <DateField source="created_at" />
      <DateField source="updated_at" />
    </Datagrid>
  </List>
);

export const MilestoneEdit = props => (
  <Edit {...props}>
    <SimpleForm>
      <NumberInput source="live_time_duration" disabled />
      <TextInput source="live_time_unit" disabled />

      <TextInput source="total_amount" disabled />
      <TextInput source="fee_amount" disabled />
      <TextInput source="amount" disabled />

      <ReferenceInput source="network_id" label="Network" reference="networks" disabled>
        <AutocompleteInput fullWidth optionText={record => record?.name} />
      </ReferenceInput>

      <ReferenceInput
        source="inventory_space_id"
        reference="inventory_spaces"
        label="Inventory Space"
        disabled
      >
        <AutocompleteInput fullWidth optionText={record => record?.name} />
      </ReferenceInput>

      <ReferenceInput source="gig_id" reference="gigs" label="Gig" disabled>
        <AutocompleteInput fullWidth optionText={record => record?.title} />
      </ReferenceInput>

      <TextInput source="rejection_reason" disabled />

      <TextInput source="url" />
      <DateInput source="delivery_by" />

      <DateTimeInput source="earliest_start_time" />
      <DateTimeInput source="latest_start_time" />
      <SelectInput source="status" choices={milestoneStatusChoices} />

      <ArrayField source="assets">
        <SingleFieldList>
          <ImageField source="url" label="Assets" />
        </SingleFieldList>
      </ArrayField>

      <ImageField source="initial_screenshot.url" label="Initial Screenshot" />
      <ImageField source="delivery_screenshot.url" label="Delivery Screenshot" />
      <ImageField source="views_screenshot.url" label="Views Screenshot" />
    </SimpleForm>
  </Edit>
);
