import { useRef, useCallback, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

import {
  Card,
  CardContent,
  CardHeader,
  Button,
  Grid,
  Typography,
  Avatar,
  Paper,
  Chip,
  Drawer,
} from '@material-ui/core';

import {
  DateField,
  useNotify,
  Create,
  Edit,
  Show,
  List,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  ImageInput,
  Datagrid,
  TextField,
  NumberField,
  ImageField,
  BooleanField,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  Labeled,
  ArrayField,
  AutocompleteInput,
  useRefresh,
  ReferenceField,
} from 'react-admin';

import axios from 'utils/axios';
import AvatarField from 'fields/AvatarField';
import TextArrayField from 'fields/TextArrayField';
import TextArrayInput from 'inputs/TextArrayInput';
import { genderChoices, audienceGenderChoices } from 'choices';
import dataProvider from 'dataProvider';
import { roleColors, userStatusColors } from 'colors';
import { InventoryItemDrawerEdit } from 'resources/inventoryItems';
import Chat from 'components/chats/Chat';
import { defaultPerPage, FRONTEND_URL } from 'utils/helpers';
import Pagination from 'components/Pagination';

const spaceAround = { display: 'flex', justifyContent: 'space-around' };
const selectContainer = { marginLeft: 10, marginRight: 10 };
const flexColumn = { display: 'flex', flexDirection: 'column' };

const matchSuggestion = (_, choice) => choice;

const UserField = ({ record }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      width: '100%',
    }}
  >
    <AvatarField source="avatar_url" record={record} />
    <span style={{ marginLeft: 20 }}>
      {record?.display_name ? `${record?.display_name} <${record?.email || record?.phone}>` : ''}
    </span>
  </div>
);

const CategoryField = props => {
  const form = useForm();
  const state = useFormState();

  return (
    <>
      <BooleanInput source="adult" />

      <BooleanInput
        source="model"
        onChange={value => {
          form.change('model', value);

          if (!value) {
            form.change('sub_category_id', null);
          }
        }}
      />

      {state.values.model && (
        <ReferenceInput
          filter={{ category_id: 1 }}
          allowEmpty
          {...props}
          label="Sub Category"
          source="sub_category_id"
          reference="sub_categories"
        >
          <SelectInput source="name" />
        </ReferenceInput>
      )}
    </>
  );
};

const AudienceExperienceAside = ({ record }) => {
  const audiencesRef = useRef();
  const experiencesRef = useRef();
  const categoriesRef = useRef();
  const notify = useNotify();

  const handleSubmit = () => {
    Promise.all([
      axios.post(`/v1/profiles/${record.id}/audiences`, {
        names: audiencesRef.current.value.split(', '),
      }),
      axios.post(`/v1/profiles/${record.id}/experiences`, {
        names: experiencesRef.current.value.split(', '),
      }),
      axios.post(`/v1/profiles/${record.id}/categories`, {
        names: categoriesRef.current.value.split(', '),
      }),
    ]).then(() => {
      notify('Successfully saved', { type: 'info' });
    });
  };

  return (
    <Card style={{ minWidth: 300, marginLeft: 20 }}>
      <CardHeader title="Audiences/Experiences" />

      <CardContent>
        <TextArrayInput
          inputRef={audiencesRef}
          source="audiences"
          record={record}
          label="Audiences"
          size="medium"
          variant="filled"
        />

        <TextArrayInput
          inputRef={experiencesRef}
          source="experiences"
          record={record}
          label="Experiences"
          size="medium"
          variant="filled"
        />

        <TextArrayInput
          inputRef={categoriesRef}
          source="categories"
          record={record}
          label="Categories"
          size="medium"
          variant="filled"
        />

        <div style={{ marginBottom: 20 }}>
          <Button fullWidth variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};

const listFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <ReferenceInput
    source="user_id"
    reference="users"
    filterToQuery={searchText => ({
      q: searchText,
      role: 'advertiser',
    })}
  >
    <AutocompleteInput
      fullWidth
      optionText={<UserField />}
      matchSuggestion={matchSuggestion}
      inputText={record =>
        record?.display_name ? `${record?.display_name} <${record?.email || record?.phone}>` : ''
      }
    />
  </ReferenceInput>,
];

const ProfileUrlField = ({ record }) => {
  if (!record?.username) return null;

  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      href={`${FRONTEND_URL}/u/${record.username}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        e.stopPropagation();
      }}
    >
      Link
    </Button>
  );
};

export const ProfileList = props => (
  <List {...props} perPage={defaultPerPage} pagination={<Pagination />} filters={listFilters}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <AvatarField source="avatar_url" label="Avatar" sortable={false} />
      <TextField source="display_name" />
      <TextField source="username" />
      <TextField source="location" />
      <TextField source="gender" />
      <TextField source="audience_gender" />
      <BooleanField source="completed" />
      <TextArrayField source="categories" sortable={false} />
      <TextField source="sub_category.name" label="Sub Category" sortable={false} />
      <TextArrayField source="audiences" sortable={false} />
      <TextArrayField source="experiences" sortable={false} />
      <ProfileUrlField label="Link" />
    </Datagrid>
  </List>
);

const ApproveButton = ({ record }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .update('influencer_networks', {
        id: record.id,
        data: {
          status: 'verified',
          profile_screenshot_status: 'verified',
          location_screenshot_status: 'verified',
          gender_screenshot_status: 'verified',
          analytics_screenshot_status: 'verified',
        },
      })
      .then(() => {
        refresh();
        notify('Successfully approved', { type: 'info' });
      });
  };

  if (record?.status === 'verified') return null;

  return (
    <Button variant="contained" color="primary" size="small" onClick={handleClick}>
      Approve
    </Button>
  );
};

const ApproveAllButton = ({ record, selectedIds }) => {
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = () => {
    dataProvider
      .updateMany('influencer_networks', {
        ids: selectedIds,
        data: {
          status: 'verified',
          profile_screenshot_status: 'verified',
          location_screenshot_status: 'verified',
          gender_screenshot_status: 'verified',
          analytics_screenshot_status: 'verified',
        },
      })
      .then(() => {
        refresh();
        notify('Successfully approved all', { type: 'info' });
      });
  };

  if (record?.status === 'verified') return null;

  return (
    <Button variant="contained" color="primary" size="small" onClick={handleClick}>
      Approve All
    </Button>
  );
};

const SocialNetworkUrl = ({ record }) => (
  <a
    onClick={e => e.stopPropagation()}
    target="_blank"
    rel="noopener noreferrer"
    href={record?.social_profile_url}
  >
    {record?.network?.name}
  </a>
);

const InfluencerNetworkList = ({ record }) => (
  <Paper style={{ marginTop: 20 }}>
    <Typography variant="h6" style={{ padding: 20 }}>
      Influencer Networks
    </Typography>

    <List
      basePath="/influencer_networks"
      resource="influencer_networks"
      perPage={25}
      filter={{ profile_id: record?.id }}
      actions={false}
      bulkActionButtons={<ApproveAllButton record={record} />}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <SocialNetworkUrl label="Network" />
        <TextField source="username" />
        <TextField source="status" />
        <TextField source="followers" />
        <ApproveButton />
      </Datagrid>
    </List>
  </Paper>
);

export const UserInfo = ({ record, label }) => (
  <Card style={{ marginBottom: 20 }}>
    <Typography
      variant="h6"
      style={{ margin: 20, display: 'flex', justifyContent: 'space-between' }}
    >
      {label || 'User'}
      <span>
        <Chip
          style={{ background: roleColors[record?.role], color: '#fff', marginRight: 5 }}
          label={record?.role}
        />

        <Chip
          variant="outlined"
          label={record?.status || 'active'}
          style={{
            color: userStatusColors[record?.status || 'active'],
            borderColor: userStatusColors[record?.status || 'active'],
          }}
        />
      </span>
    </Typography>

    <CardHeader
      avatar={<Avatar src={record?.avatar_url} />}
      title={record?.name}
      subheader={record?.email || record?.phone}
    />
  </Card>
);

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const InventoriesListDrawer = ({ record, basePath }) => {
  const [open, setOpen] = useState(null);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setOpen(null);
  }, []);

  const inventories = record?.inventories;

  if (!inventories || !inventories.length) return null;

  return (
    <>
      <Paper style={{ marginTop: 20 }}>
        <Typography variant="h6" style={{ padding: 20 }}>
          Inventory
        </Typography>

        {inventories.map(inventory => (
          <div style={{ marginBottom: 20 }}>
            <strong style={{ display: 'block', marginLeft: 20, marginBottom: 20 }}>
              {inventory?.influencer_network?.network?.name} {inventory?.inventory_space?.name}{' '}
            </strong>

            <ArrayField
              resource="inventory_items"
              basePath="/inventory_items"
              source="inventory_items"
              record={inventory}
            >
              <Datagrid
                rowClick={(_, __, record) => {
                  setOpen(record.id);
                }}
              >
                <NumberField source="average_views" />
                <NumberField source="min_guaranteed_views" />

                <NumberField source="live_time_duration" />
                <TextField source="live_time_unit" />

                <BooleanField source="default" />
                <NumberField source="price" />
              </Datagrid>
            </ArrayField>
          </div>
        ))}
      </Paper>

      <Drawer
        variant="persistent"
        open={!!open}
        anchor="right"
        onClose={handleClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {open ? (
          <InventoryItemDrawerEdit
            profileId={record?.id}
            id={open}
            onCancel={handleClose}
            resource="inventory_items"
            basePath={basePath}
          />
        ) : null}
      </Drawer>
    </>
  );
};

const InventoriesList = ({ record }) => {
  const inventories = record?.inventories;

  if (!inventories || !inventories.length) return null;

  return (
    <Paper style={{ marginTop: 20 }}>
      <Typography variant="h6" style={{ padding: 20 }}>
        Inventory
      </Typography>

      {inventories.map(inventory => (
        <div style={{ marginBottom: 20 }}>
          <strong style={{ display: 'block', marginLeft: 20, marginBottom: 20 }}>
            {inventory?.influencer_network?.network?.name} {inventory?.inventory_space?.name}{' '}
          </strong>

          <ArrayField
            resource="inventory_items"
            basePath="/inventory_items"
            source="inventory_items"
            record={inventory}
          >
            <Datagrid rowClick={(_, __, record) => `/inventory_items/${record.id}`}>
              <NumberField source="average_views" />
              <NumberField source="min_guaranteed_views" />

              <NumberField source="live_time_duration" />
              <TextField source="live_time_unit" />

              <BooleanField source="default" />
              <NumberField source="price" />
            </Datagrid>
          </ArrayField>
        </div>
      ))}
    </Paper>
  );
};

export const GigList = ({ profileId }) => (
  <Paper style={{ marginTop: 20 }}>
    <div style={{ padding: 20 }}>
      <Typography variant="h6">Gigs</Typography>
    </div>

    <List
      basePath="/gigs"
      resource="gigs"
      perPage={25}
      filter={{ profile_id: profileId }}
      actions={false}
      bulkActionButtons={false}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="title" />
        <TextField source="status" />
        <BooleanField source="public" />

        <ReferenceField source="user_id" reference="users" label="Advertiser" sortable={false}>
          <TextField source="display_name" />
        </ReferenceField>

        <NumberField source="budget" />
        <NumberField source="advertiser_fee_amount" />
        <NumberField source="total_amount" />
        <NumberField source="amount" />
        <NumberField source="influencer_fee_amount" />

        <DateField source="created_at" />
        <DateField source="expires_at" />
      </Datagrid>
    </List>
  </Paper>
);

const EditLayout = props => (
  <>
    <div>
      <ReferenceField label="User" source="user_id" reference="users">
        <UserInfo />
      </ReferenceField>
    </div>

    <Paper>
      <SimpleForm {...props}>
        <Typography variant="h6" style={{ marginLeft: 10 }}>
          Profile
        </Typography>

        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item md={6}>
            <div style={selectContainer}>
              <ReferenceInput
                source="user_id"
                reference="users"
                filterToQuery={searchText => ({
                  q: searchText,
                  role: 'influencer',
                })}
              >
                <AutocompleteInput
                  fullWidth
                  optionText={<UserField />}
                  matchSuggestion={matchSuggestion}
                  inputText={record => `${record?.name} - ${record?.id}`}
                />
              </ReferenceInput>
            </div>

            <div style={spaceAround}>
              <TextInput source="display_name" style={{ width: '46%' }} />
              <TextInput source="username" style={{ width: '46%' }} />
            </div>

            <div style={selectContainer}>
              <SelectInput fullWidth source="audience_gender" choices={audienceGenderChoices} />
              <SelectInput
                fullWidth
                source="gender"
                label="Gender (identification)"
                choices={genderChoices}
              />
            </div>

            <div style={selectContainer}>
              <TextInput fullWidth source="location" disabled />
              <BooleanInput source="completed" />
              <BooleanInput source="hidden" />
              <BooleanInput source="allow_early_delivery" />
              <BooleanInput source="discoverable" />
            </div>
          </Grid>

          <Grid item md={6} style={flexColumn}>
            <CategoryField />

            <Labeled label="Current Avatar">
              <ImageField source="avatar_url" label="Current Avatar" />
            </Labeled>

            <ImageInput source="avatar">
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Paper>

    <InfluencerNetworkList {...props} />
    <InventoriesList profileId={props?.record?.id} {...props} />
    <GigList profileId={props?.record?.id} />

    {/* <Paper style={{ marginTop: 20 }}>
      <Typography variant="h6" style={{ padding: 20 }}>
        Chat
      </Typography>

      <Chat recipientType="Profile" recipientId={props?.record?.id} sidebar={false} />
    </Paper> */}
  </>
);

export const ProfileEdit = props => (
  <Edit component="div" aside={<AudienceExperienceAside />} {...props} mutationMode="pessimistic">
    <EditLayout {...props} />
  </Edit>
);

export const ProfileCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <Grid container spacing={2} style={{ width: '100%' }}>
        <Grid item md={6}>
          <div style={selectContainer}>
            <ReferenceInput
              source="user_id"
              reference="users"
              filterToQuery={searchText => ({
                q: searchText,
                role: 'influencer',
              })}
            >
              <AutocompleteInput
                fullWidth
                optionText={<UserField />}
                matchSuggestion={matchSuggestion}
                inputText={record =>
                  record?.display_name
                    ? `${record?.display_name} <${record?.email || record?.phone}>`
                    : ''
                }
              />
            </ReferenceInput>
          </div>

          <div style={spaceAround}>
            <TextInput source="display_name" style={{ width: '48%' }} />
            <TextInput source="username" style={{ width: '48%' }} />
          </div>

          <div style={selectContainer}>
            <SelectInput fullWidth source="audience_gender" choices={genderChoices} />
            <SelectInput fullWidth source="gender" choices={genderChoices} />
          </div>

          <div style={selectContainer}>
            <TextInput fullWidth source="location" disabled />
            <BooleanInput source="completed" />
          </div>
        </Grid>

        <Grid item md={6} style={flexColumn}>
          <CategoryField />

          <ImageInput source="avatar">
            <ImageField source="src" title="title" />
          </ImageInput>
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export const ProfileShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="display_name" />
      <TextField source="username" />
      <TextField source="location" />
      <TextField source="gender" />
      <TextField source="audience_gender" />
      <BooleanField source="completed" />

      <TextField source="category.name" label="Category" />
      <TextField source="sub_category.name" label="Sub Category" />

      <ImageField source="avatar_url" />

      <TextArrayField source="audiences" />
      <TextArrayField source="experiences" />

      <ArrayField source="inventories">
        <Datagrid rowClick="edit">
          <NumberField source="weekly_availability" />
          <NumberField source="influencer_network.network.name" label="Network" />
          <NumberField source="inventory_space.name" label="Inventory Space" />

          <ArrayField source="inventory_items">
            <Datagrid rowClick="edit">
              <NumberField source="average_views" />
              <NumberField source="min_guaranteed_views" />

              <NumberField source="live_time_duration" />
              <TextField source="live_time_unit" />

              <BooleanField source="default" />
              <NumberField source="price" />
            </Datagrid>
          </ArrayField>
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);
